import Image from "next/image";
import React, { Component } from "react";
import Header from "../components/Header/Header";
import HeaderLinks from "../components/Header/HeaderLinks";
import HeaderLeftLinks from "../components/Header/HeaderLeftLinks";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Footer from "../components/Footer/Footer";
import { Color, Color as Colors } from "../src/utils/Color";
import { FontSize, FontSize as FontSizes } from "../src/utils/FontSize";
import { withTheme, useTheme } from "styled-components";
import styled from "styled-components";
import Hidden from "@material-ui/core/Hidden";
import DesktopBannerContainer from "../components/Home/DesktopBannerContainer";
import MobileBannerContainer from "../components/Home/MobileBannerContainer";
import CustomFont from "../components/CustomFont";
import { maxDevice } from "../src/utils/breakpoints";
import Router from "next/router";
import * as selectors from "../src/selectors";
import * as globalActions from "../src/actions/global";
import { connect } from "dva";
import HomeProjectItem from "../components/HomeProjectItem";
import HomePropertyItem from "../components/HomePropertyItem";
import * as globalSelectors from "../src/selectors/global";
import _ from "lodash";
import * as searchActions from "../src/actions/search";
import Constants from "../src/constants";
import Helpers from "../src/utils/Helpers";
import { NextSeo } from "next-seo";
import BlogCardItem from "../components/Blogs/BlogCardItem";
import "aos/dist/aos.css";
import Divider from "@material-ui/core/Divider";
import PropertyToolSection from "../components/Home/PropertyToolSection";
import Skeleton from "@mui/material/Skeleton";
import AgentPackageFeatured from "../components/AgentPackageFeatured";

const BannerImageBackgroundContainer = styled(Paper)`
  height: auto;
  width: 100%;
  border-radius: 20px !important;
  display: flex;
  flex: 1;
  background-size: cover;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 160px 0;
  @media ${maxDevice.sm} {
    padding: 80px 0px;
  }
`;

const CardBackgroundContainer = styled(Card)`
  min-height: 20vh;
  background-repeat: no-repeat;
  height: 100%;
  border-radius: 20px !important;
  background-size: cover;
  padding: 1em 1.5em;
  background-color: transparent;
  @media ${maxDevice.sm} {
    padding-bottom: 60px;
  }
`;

const CardContentContainer = styled(CardContent)`
  width: 70%;
  @media ${maxDevice.sm} {
    width: 90%;
  }
`;

const ImageCoursel = styled(Image)`
  height: 100%;
  width: 100%;
`;

const EventBackgroundContainer = styled(Card)`
  height: 100%;
  border-radius: 4px !important;
`;

const EventItemContainer = styled(CardContent)`
  background-color: ${Colors.primaryColor};
  padding: 20px !important;
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
`;

const TextFieldContainer = styled(TextField)`
  background-color: transparent;

  & .MuiFilledInput-root {
    background-color: transparent;

    &:before {
      border-bottom: 0 !important;
    }

    &:hover {
      outline: 0 !important;
      background-color: transparent;
    }

    &:focus {
      outline: 0 !important;
    }
  }

  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: ${Colors.textWhite};
    }

    &:hover fieldset {
      border-color: ${Colors.textWhite};
    }

    &.Mui-focused fieldset {
      border-color: ${Colors.textWhite};
    }
  }
`;

const textFieldClass = theme => ({
  root: {
    backgroundColor: "white"
  }
});

const TitleItemContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  margin-bottom: 10px;
  & h2 {
    font-size: 1.5rem !important;
  }
  @media ${maxDevice.md} {
    & h2 {
      font-size: 1.3rem !important;
    }
  }
`;

const AgentContainer = styled.div`
  // background-image: linear-gradient(to right, #355cee, #3721b4);
  // border-radius: 20px;
  //padding: 30px 40px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

const AgentMobileContainer = styled.div`
  background-image: linear-gradient(to right, #355cee, #3721b4);
  border-radius: 10px;
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BannerImageContainer = styled.div`
  position: relative;
  height: 50vh;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  @media ${maxDevice.md} {
    height: 30vh;
    & h2 {
      font-size: 1.2rem !important;
    }
  }
`;

const BannerContentContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
`;
const BannerContentContainerInner = styled.div`
  padding: 40px 40px;
  @media ${maxDevice.md} {
    //padding: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    padding: 0px 40px;
  }
`;

const SmallBannerContainer = styled.div`
  width: 100%;
  padding: 40px 40px;
  text-align: center;
  height: 100%;
  border-radius: 4px;
`;

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const PreLandingLeftContent = styled.div`
  background-image: url("/images/world-map.png");
  background-size: auto;
  background-position: center;
  background-repeat: no-repeat;
  height: 45vh;
  position: relative;
  display: flex;
  justify-content: center;
`;
const PreLandingRightContent = styled.div``;

const LabelDetailContainer = styled.div`
    // @media ${maxDevice.md} {
  //   text-align: center;
  // }
`;
//====Previous Design SignUP banner=====
const SignUpDetailContainer = styled.div`
  @media ${maxDevice.md} {
    display: flex;
    justify-content: center;
  }
`;
const ButtonSignUpContainer = styled.div`
    // @media ${maxDevice.md} {
  //   display: flex;
  //   justify-content: center;
  // }
`;
//====End of Previous Design SignUP banner=====
//====Current Design SignUp banner=======
const SignUpBannerSection = styled.div`
  position: relative;
  display: flex;
  border-radius: 20px;
  background: linear-gradient(91deg, #425ae8 0.42%, #3b2cb7 99.37%);
  height: 300px;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
  @media ${maxDevice.lg} {
    height: 230px;
  }
`;
const SignUpBannerContainer = styled.div`
  padding-right: 200px;
  padding-left: 50px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;

  & h3 {
    font-size: 1rem !important;
    font-weight: 500 !important;
  }
  & h2 {
    font-size: 1.5rem !important;
    padding-right: 230px !important;
    margin: 30px 0px 10px 0;
  }

  @media ${maxDevice.lg} {
    padding-right: 0px;
    padding-left: 10px;
    & h2 {
      margin: 15px 0px 5px 0;
      padding-right: 50px !important;
      font-size: 1rem !important;
    }
    & h2 {
      font-size: 0.9rem !important;
    }
  }
`;
const SignUpLeftBannerContainer = styled.div`
  width: 600px;
  height: 100%;
  display: flex;
  position: relative;
  //justify-content: flex-end;
  @media ${maxDevice.lg} {
    //width: 69vw;
    width: 300px;
    margin-top: 120px;
    margin-right: -10px;
  }
`;
// ====End of Current Design SignUp banner=======
//New design CSS
const BannerImageContainerOutter = styled.div`
  position: relative;
  //box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  //display: flex;
  height: 200px;
  justify-content: flex-end;
  display: flex;
  align-items: center;
  max-width: 550px;
  width: 100%;
  @media ${maxDevice.md} {
    height: 150px;
  }
`;
const BannerImageContainerText = styled.div`
  padding-right: 220px;
  padding-left: 20px;
  //flex-basis: 380px;
  position: absolute;
  //inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;

  & h2 {
    font-size: ${FontSizes.large}!important;
  }
  & .MuiButton-root {
    font-size: 14px;
  }
  @media ${maxDevice.xl} {
    padding-right: 200px;
  }
  @media ${maxDevice.md} {
    & h2 {
      font-size: ${FontSizes.small}!important;
    }
    & .MuiButton-root {
      font-size: 12px;
    }
  }
`;
const BannerImageContainerInnerText = styled.div`
  width: 100%;

  @media ${maxDevice.md} {
    width: 150px;
  }
`;
const BoxInner = styled.div`
  margin-top: 6rem;
  margin-bottom: 3rem;
  @media ${maxDevice.md} {
    margin-top: 4rem;
    margin-bottom: 1rem;
  }
`;
const BannerImageContentOutter = styled.div`
  position: relative;
  width: 305px;
  height: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: flex-end;
  @media ${maxDevice.md} {
    width: 240px;
  }
`;
const PropertyToolTitleContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & h1 {
    font-size: 1.5rem !important;
  }
  @media ${maxDevice.md} {
    & h1 {
      font-size: 1.3rem !important;
    }
  }
`;

const HomeBigTitle = styled.h1`
  color: rgb(255, 255, 255);
  font-size: 2rem;
  font-weight: 600;
  text-shadow: rgba(0, 0, 0, 0.64) 0px 3px 5px;
  display: inline-block;
  line-height: 1.167;
  position: absolute;
  //max-width: 60vw;
  width: 100%;
  text-align: center;
  z-index: 99;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -200%);
  @media ${maxDevice.md} {
    font-size: 1.3rem !important;
    width: 300px;
    text-align: center;
    transform: translate(-50%, -133%);
  }
`;

class Home extends Component {
  constructor(props) {
    super(props);
    const debounceSearchSuggestion = _.debounce(
      value => {
        this.startSearchSuggestion(value);
      },
      500,
      false
    );
    const debounceSearchRentSuggestion = _.debounce(
      value => {
        this.startSearchSuggestionRent(value);
      },
      500,
      false
    );
    this.state = {
      tabIndex: 0,
      searchText: "",
      anchorEL: null,
      visible: false,
      buySearchText: "",
      rentSearchText: "",
      focus: false,
      buyItem: null,
      debounceSearchSuggestionTrigger: value => debounceSearchSuggestion(value),
      debounceSearchRentSuggestionTrigger: value =>
        debounceSearchRentSuggestion(value),
      buyCoordinate: null,
      rentCoordinate: null,
      gmapsLoaded: false,
      isLoading: true
    };
  }

  componentDidMount() {
    const { getHomeData, homeData } = this.props;
    // ==== For remove session of Napic previous URL ====
    sessionStorage.removeItem('prevUrlNapic');
    sessionStorage.removeItem('prevUrlLogin');
    // ==== End of remove session of napic previous URL ====
    getHomeData && getHomeData();
    //setLanguage && setLanguage("zh");
    window.initMap = this.initMap;
    const gmapScriptEl = document.createElement(`script`);
    gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${
      process.env.GOOGLE_MAP_KEY
    }&libraries=places&fields=geometry&callback=initMap`;
    document
      .querySelector(`body`)
      .insertAdjacentElement(`beforeend`, gmapScriptEl);
  }

  initMap = () => {
    this.setState({
      gmapsLoaded: true
    });
  };

  onChangeTab = (e, value) => {
    this.setState({ tabIndex: value });
  };

  onClickBuySearch = () => {
    const { setFilterData, setCoordinateData } = this.props;

    const { buyCoordinate } = this.state;

    if (!_.isEmpty(buyCoordinate)) {
      setCoordinateData(buyCoordinate);
    }

    const searchBuyText = decodeURIComponent(this.state.buySearchText).replace(
      / /g,
      "-"
    );

    setFilterData(Constants.SECTION_PROPERTY_SALE, {
      keyword: this.state.buySearchText
    });
    Router.push({
      pathname: "/property-for-sale",
      query: { q: searchBuyText }
    });
  };

  setCoordinate = (buyItem, isBuy = false) => {
    const { setCoordinateData } = this.props;

    const placeID = _.get(buyItem, ["placeId"], null);

    if (placeID === null) {
      return;
    }
    var pyrmont = new google.maps.LatLng(-33.8665433, 151.1956316);

    const map = new google.maps.Map(document.getElementById("map"), {
      center: pyrmont,
      zoom: 15
    });

    const request = {
      placeId: placeID,
      fields: ["geometry"]
    };

    const service = new google.maps.places.PlacesService(map);
    service.getDetails(request, place => {
      if (isBuy) {
        this.setState({
          buyCoordinate: {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
          }
        });
      } else {
        this.setState({
          rentCoordinate: {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
          }
        });
      }
    });
  };

  onClickRentSearch = () => {
    const { setFilterData, setCoordinateData } = this.props;

    const { rentCoordinate } = this.state;
    if (!_.isEmpty(rentCoordinate)) {
      setCoordinateData(rentCoordinate);
    }
    setFilterData(Constants.SECTION_PROPERTY_RENT, {
      keyword: this.state.rentSearchText
    });

    const searchRentText = decodeURIComponent(
      this.state.rentSearchText
    ).replace(/ /g, "-");

    Router.push({
      pathname: "/property-for-rent",
      query: { q: searchRentText }
    });
  };

  onClickProjectItem = id => {
    Router.push({
      pathname: "/project/[slug]",
      query: { slug: id }
    });
  };

  onClickPropertyItem = slug => {
    Router.push({
      pathname: "/property/[slug]",
      query: { slug: slug }
    });
  };

  onClickNewsItem = slug => {
    Router.push({
      pathname: "/news-and-events/[slug]",
      query: { slug: slug }
    });
  };

  onFocusText = (e, anchorEL) => {
    this.setState(
      {
        anchorEL: anchorEL
      },
      () => this.setState({ visible: true })
    );
  };

  handleClose = () => {
    this.setState({ visible: false });
  };

  // onChangeBuyText = e => {
  //   const { debounceSearchSuggestionTrigger } = this.state;
  //   this.setState(
  //     {
  //       buySearchText: e,
  //       buyCoordinate: null
  //     },
  //     () => {
  //       //  debounceSearchSuggestionTrigger(e.target.value);
  //     }
  //   );
  // };
  handleSearch = (buySearchText) => {
    const { commonDataState } = this.props;

    if (_.isEmpty(buySearchText.trim())) {
      this.setState({ suggestionsLocal: [] });
      return;
    }

    const filteredData = _.filter(
        commonDataState,
        (item) =>
            item.name.toLowerCase().includes(buySearchText.toLowerCase())
    );

    this.setState({ suggestionsLocal: filteredData });
  };

  handleSearchCity = (buySearchText) => {
    const { commonDataCity } = this.props;

    if (_.isEmpty(buySearchText.trim())) {
      this.setState({ suggestionsCity: [] });
      return;
    }

    const filteredDataCity = _.filter(
        commonDataCity,
        (item) =>
            item.name.toLowerCase().includes(buySearchText.toLowerCase())
    );

    this.setState({ suggestionsCity: filteredDataCity });
  };

  debouncedSearch = _.debounce((buySearchText) => {
    this.handleSearch(buySearchText);
  }, 3000); // Wait for 3 seconds after the user stops typing
   debouncedSearchCity = _.debounce((buySearchText) => {
    this.handleSearchCity(buySearchText);
  }, 3000); // Wait for 3 seconds after the user stops typing

  onChangeBuyText = e => {
    const { debounceSearchSuggestionTrigger } = this.state;
    this.setState(
      {
        buySearchText: e,
        buyCoordinate: null
      },
      () => {
        //  debounceSearchSuggestionTrigger(e.target.value);
      }
    );
    this.debouncedSearch(e);
    this.debouncedSearchCity(e);
  };

  startSearchSuggestion = value => {
    const { postSearchSuggestion, clearSearchSuggestion } = this.props;
    const route = "homeBuy";

    if (_.isEmpty(value)) {
      clearSearchSuggestion && clearSearchSuggestion(route);
      return;
    }
    postSearchSuggestion && postSearchSuggestion(route, { keyword: value });
  };

  startSearchSuggestionRent = value => {
    const { postSearchSuggestion, clearSearchSuggestion } = this.props;
    const route = "homeRent";

    if (_.isEmpty(value)) {
      clearSearchSuggestion && clearSearchSuggestion(route);
      return;
    }

    postSearchSuggestion && postSearchSuggestion(route, { keyword: value });
  };

  onChangRentText = e => {
    const { debounceSearchRentSuggestionTrigger } = this.state;
    this.setState(
      {
        rentSearchText: e,
        rentCoordinate: null
      },
      () => {
        // debounceSearchRentSuggestionTrigger(e.target.value);
      }
    );
  };

  onClickGlobal = e => {
    // e.preventDefault();

    if (this.state.focus) {
      this.setState({ focus: false });
    }
  };

  onClickFocus = () => {
    this.setState({ focus: true });
  };

  onClickItem = title => {
    const { debounceSearchSuggestionTrigger } = this.state;
    const description = _.get(title, ["description"], null);
    this.setCoordinate(title, true);
    this.setState(
      {
        buySearchText: description
      }
      // () => debounceSearchSuggestionTrigger(description)
    );
    if (this.state.focus) {
      this.setState({ focus: false });
    }
  };

  onClickRentItem = title => {
    const { debounceSearchRentSuggestionTrigger } = this.state;
    const description = _.get(title, ["description"], null);
    this.setCoordinate(title);
    this.setState(
      {
        rentSearchText: description
      }
      //() => debounceSearchRentSuggestionTrigger(title)
    );
    if (this.state.focus) {
      this.setState({ focus: false });
    }
  };

  onClickBeAgent = e => {
    e.preventDefault();
    const hostname = Helpers.isProduction
      ? typeof window !== "undefined" && window.location.hostname
        ? window.location.hostname
        : ""
      : "localhost:3000";
    const http = Helpers.isProduction ? "https" : "http";
    window.open(`${process.env.WEBSITE_URL}agent-sign-in`, "_blank");
  };

  onClickPremierAgent = e => {
    e.preventDefault();
    const hostname = Helpers.isProduction
      ? typeof window !== "undefined" && window.location.hostname
        ? window.location.hostname
        : ""
      : "localhost:3000";
    const http = Helpers.isProduction ? "https" : "http";
    window.open(`${process.env.WEBSITE_URL}premier-agent`, "_blank");
    // Router.push({
    //   pathname: "https://agents.propertygenie.com.my/"});
  };

  onClickCalculate = e => {
    e.preventDefault();
    const hostname = Helpers.isProduction
      ? typeof window !== "undefined" && window.location.hostname
        ? window.location.hostname
        : ""
      : "localhost:3000";
    const http = Helpers.isProduction ? "https" : "http";
    window.open(`${process.env.WEBSITE_URL}home-loan-calculator`, "_blank");
  };

  onClickComingSoon = e => {
    e.preventDefault();
    const hostname = Helpers.isProduction
      ? typeof window !== "undefined" && window.location.hostname
        ? window.location.hostname
        : ""
      : "localhost:3000";
    const http = Helpers.isProduction ? "https" : "http";
    window.open(`${process.env.WEBSITE_URL}coming-soon`, "_blank");
  };

  onClickGuide = e => {
    e.preventDefault();
    // const hostname = Helpers.isProduction
    //   ? typeof window !== "undefined" && window.location.hostname
    //     ? window.location.hostname
    //     : ""
    //   : "localhost:3000";
    // const http = Helpers.isProduction ? "https" : "http";
    // window.open(`${process.env.WEBSITE_URL}guide`, "_blank");
    window.open(`${process.env.WEBSITE_URL}transaction-and-statistics`, "_blank");
  };

  onClickInsiderGuide = e => {
    e.preventDefault();
    const hostname = Helpers.isProduction
      ? typeof window !== "undefined" && window.location.hostname
        ? window.location.hostname
        : ""
      : "localhost:3000";
    const http = Helpers.isProduction ? "https" : "http";
    window.open(`${process.env.WEBSITE_URL}insider-guide`, "_blank");
  };

  onClickBlogItem = (slug) => {
    // e.preventDefault();
    Router.push({
      pathname: "/insider-guide/[slug]",
      query: { slug: slug }
    });
  };
  handleButtonLanding = () => {
    Router.push("/signup-landing");
  };

  getEmptyArray = width => {
    if (width > 4000) {
      return 5;
    }
    if (width > 1024 && width < 3000) {
      return 4;
    }
    if (width > 464 && width < 1024) {
      return 2;
    }
    if (width > 0 && width < 464) {
      return 1;
    }
  };

  render() {
    const {
      theme,
      homeLoading,
      homeData,
      searchSuggestionItems,
      searchRentSuggestionItems,
      isLoading,
      commonDataState,
      commonDataCity
    } = this.props;

    const {
      anchorEL,
      visible,
      buySearchText,
      rentSearchText,
      focus,
      gmapsLoaded,
      suggestionsLocal,
      suggestionsCity
    } = this.state;

    // const windowWidth =
    const emptyArrayViewItem = this.getEmptyArray(2000);
    const latestProjects = globalSelectors.getHomeProjectLatestItems(homeData);
    const latestGuides = globalSelectors.getHomeGuidesLatestItems(homeData);
    const handpickProperty = globalSelectors.getHomePropertyHandpickItems(
      homeData
    );

    return (
      <div style={{ overflow: "hidden" }} onClick={this.onClickGlobal}>
        <NextSeo
          defaultTitle="Search Properties for Sale or Rent in Malaysia | PropertyGenie.com.my"
          title="Search Properties for Sale or Rent in Malaysia | PropertyGenie.com.my"
          description={"Find your next home in Malaysia with Property Genie. Buy or rent from our extensive listings on Malaysia's fastest-growing property platform, offering a wide range of properties to suit every need."}
          // description="Explore Malaysia's property with Property Genie: find homes and commercial spaces for sale or rent. We provide user-friendly platform with interactive maps, quality images, and expert insights. Connect with top agents and developers. Start your journey today!"
          canonical="https://www.propertygenie.com.my"
          openGraph={{
            url: "https://www.propertygenie.com.my",
            title: "Search Properties for Sale or Rent in Malaysia | PropertyGenie.com.my",
            // description:
            //   "Explore Malaysia's property with Property Genie: find homes and commercial spaces for sale or rent. We provide user-friendly platform with interactive maps, quality images, and expert insights. Connect with top agents and developers. Start your journey today!",
            description: "Find your next home in Malaysia with Property Genie. Buy or rent from our extensive listings on Malaysia's fastest-growing property platform, offering a wide range of properties to suit every need.",
            images: [
              {
                url: "https://www.propertygenie.com.my/logo-square-default.jpg",
                width: 500,
                height: 500,
                alt: "Property Genie",
                type: "image/jpeg",
                secureUrl:
                  "https://www.propertygenie.com.my/logo-square-default.jpg"
              }
            ],
            siteName: "Search Properties for Sale or Rent in Malaysia | PropertyGenie.com.my",
            type: "website",
            locale: "en_IE"
          }}
          twitter={{
            handle: "@property_genie",
            site: "@site",
            cardType: "summary_large_image"
          }}
          // robotsProps={{
          //   nosnippet: true,
          //   notranslate: true,
          //   noimageindex: true,
          //   noarchive: true,
          //   maxSnippet: -1,
          //   maxImagePreview: "none",
          //   maxVideoPreview: -1
          // }}
          facebook={{ appId: 1397160940782930 }}
        />

        <Header
          brand={true}
          key="home"
          rightLinks={<HeaderLinks />}
          leftLinks={<HeaderLeftLinks />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
          style={{
            boxShadow: "none",
            borderBottom: `1px solid ${Colors.borderColor}`
          }}
        />
        {/*=====For Sitelink search purpose=====*/}
        {/*<script type="application/ld+json">*/}
        {/*  {`*/}
        {/*  {*/}
        {/*    "@context": "https://schema.org",*/}
        {/*    "@type": "WebSite",*/}
        {/*    "url": "https://www.propertygenie.com.my",*/}
        {/*    "potentialAction": {*/}
        {/*    "@type": "SearchAction",*/}
        {/*    "target": {*/}
        {/*    "@type": "EntryPoint",*/}
        {/*    "urlTemplate": "https://www.propertygenie.com.my/property-for-sale?q={search_term_string}"*/}
        {/*  },*/}
        {/*    "query-input": "required name=search_term_string"*/}
        {/*  }*/}
        {/*  }*/}
        {/*  `}*/}
        {/*</script>*/}
        {/*=====End of Sitelink search purpose=====*/}

        <div id="map" />
        <div style={{ position: "relative" }}>
          <HomeBigTitle>
            Find Your Next Home In Minutes.
          </HomeBigTitle>
        <Box
            sx={{
              display: { xs: 'block', md: 'none' }
            }}
        >
          <MobileBannerContainer
            onClickBuySearch={this.onClickBuySearch}
            onClickRentSearch={this.onClickRentSearch}
            onChangeBuyText={this.onChangeBuyText}
            onChangeRentText={this.onChangRentText}
            rentSearchText={rentSearchText}
            buySearchText={buySearchText}
            focus={focus}
            onClickFocus={this.onClickFocus}
            onClickItem={this.onClickItem}
            searchSuggestionItems={searchSuggestionItems}
            onClickRentItem={this.onClickRentItem}
            searchRentSuggestionItems={searchRentSuggestionItems}
            gmapsLoaded={gmapsLoaded}
            commonDataState={commonDataState}
            commonDataCity={commonDataCity}
          />
        </Box>
        <Box
            sx={{
              display: { xs: 'none', md: 'block' }
            }}
        >
          <DesktopBannerContainer
            onClickBuySearch={this.onClickBuySearch}
            onClickRentSearch={this.onClickRentSearch}
            onChangeBuyText={this.onChangeBuyText}
            onChangeRentText={this.onChangRentText}
            rentSearchText={rentSearchText}
            buySearchText={buySearchText}
            focus={focus}
            onClickFocus={this.onClickFocus}
            onClickItem={this.onClickItem}
            searchSuggestionItems={searchSuggestionItems}
            onClickRentItem={this.onClickRentItem}
            searchRentSuggestionItems={searchRentSuggestionItems}
            gmapsLoaded={gmapsLoaded}
            commonDataState={commonDataState}
            commonDataCity={commonDataCity}
            suggestionsLocal={suggestionsLocal}
            suggestionsCity={suggestionsCity}
          />
        </Box>
        </div>
        {/*<Box sx={{ marginBottom: "2rem", marginTop: "2rem" }}>*/}
        {/*  <Container>*/}
        {/*    <Grid container spacing={2} />*/}
        {/*  </Container>*/}
        {/*</Box>*/}
        {/*====SEO Meta Homepages======*/}
        <h2 style={{ visibility: "hidden", maxHeight: "0" }}>
          Buy or Rent Property in Malaysia with the Most Comprehensive Listings
          of Property for Sale or for Rent on Property Genie Malaysia, Asia’s
          Leading Property Portal.
        </h2>
        {/*<Hidden smDown>*/}
        <Box sx={{ backgroundColor: 'white' }}>
          <BoxInner>
          <Container>
            <Grid container spacing={2}>
              {/*=======New Design Section====*/}
              <Grid item md={1} xs={12} />
              <Grid
                item
                md={5}
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <BannerImageContainerOutter>
                  <BannerImageContentOutter>
                    <Image
                      src="/images/Banner-Image-3.png"
                      layout="fill"
                      objectFit="cover"
                      alt="home-banner-1"
                    />
                  </BannerImageContentOutter>
                  <BannerImageContainerText>
                    <BannerImageContainerInnerText>
                      <CustomFont
                          variant="h2"
                          styles={{
                            color: Colors.blackColor,
                            fontWeight: "600"
                          }}
                      >
                        {/*Let Us Guide You To Your New Home*/}
                        Discover Malaysia Latest Property Transaction
                        Prices & Data Now!
                      </CustomFont>
                    </BannerImageContainerInnerText>
                    <Button
                      onClick={this.onClickGuide}
                      variant="contained"
                      style={{
                        textTransform: "none",
                        padding: "6px 15px",
                        background: Colors.primaryColor,
                        color: Colors.textWhite,
                        fontWeight: "600",
                        borderRadius: "4px",
                        marginTop: 20,
                        width: "fit-content"
                      }}
                      size="large"
                      href={`${process.env.WEBSITE_URL}transaction-and-statistics`}
                    >
                      Get Started
                    </Button>
                  </BannerImageContainerText>
                </BannerImageContainerOutter>
              </Grid>
              {/*2nd Image*/}
              <Grid
                item
                md={5}
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <BannerImageContainerOutter>
                  <BannerImageContentOutter>
                    <Image
                      src="/images/Banner-Image-1.png"
                      layout="fill"
                      objectFit="cover"
                      alt="home-banner-2"
                      priority
                    />
                  </BannerImageContentOutter>
                  <BannerImageContainerText>
                    <BannerImageContainerInnerText>
                      <CustomFont
                          variant="h2"
                          styles={{
                            color: Colors.blackColor,
                            fontWeight: "600"
                          }}
                      >
                        Unlock possibilities with PropertyGenie Premier Agent
                      </CustomFont>
                    </BannerImageContainerInnerText>
                    <Button
                      onClick={this.onClickPremierAgent}
                      variant="contained"
                      style={{
                        textTransform: "none",
                        padding: "6px 15px",
                        background: Colors.primaryColor,
                        color: Colors.textWhite,
                        fontWeight: "600",
                        borderRadius: "4px",
                        marginTop: 20,
                        width: "fit-content"
                      }}
                      size="large"
                      href={`${process.env.WEBSITE_URL}premier-agent`}
                    >
                      Get Started
                    </Button>
                  </BannerImageContainerText>
                </BannerImageContainerOutter>
              </Grid>
              <Grid item md={1} xs={12} />
            </Grid>
            {/*=======End of New Design Section====*/}
          </Container>
            </BoxInner>
        </Box>
        {/*</Hidden>*/}
        {/*=======Mobile version=======*/}
        {/*<Hidden mdUp>*/}
        {/*  <Box sx={{ marginBottom: "2rem", marginTop: "2rem" }}>*/}
        {/*    <Container style={{ display: "flex", justifyContent: "center" }}>*/}
        {/*      <MobileBannerImageContainer />*/}
        {/*    </Container>*/}
        {/*  </Box>*/}
        {/*</Hidden>*/}

        {/*{!_.isEmpty(handpickProperty) ? (*/}
        <Box
          sx={{
            paddingTop: "2rem",
            paddingBottom: "1rem",
            backgroundColor: Colors.greyBackgroundColor
          }}
        >
          <Container>
            <Grid item md={12}>
              <TitleItemContainer>
                <CustomFont
                  styles={{
                    fontWeight: "600"
                  }}
                  variant="h2"
                >
                  Latest Listed Properties
                </CustomFont>
              </TitleItemContainer>
            </Grid>
            {/*<Carousel*/}
            {/*  responsive={responsive}*/}
            {/*  // showDots={true}*/}
            {/*  ssr={true}*/}
            {/*  draggable={false}*/}
            {/*  swipeable={true}*/}
            {/*  onMove*/}
            {/*  focusOnSelect={false}*/}
            {/*  // containerClass="homePageNewAndEventCarouselContainer"*/}
            {/*  dotListClass="custom-dot-list-style"*/}
            {/*  itemClass="carousel-item-padding-40-px"*/}
            {/*>*/}
            {/*  {homeLoading*/}
            {/*    ? _.map(Array(emptyArrayViewItem), () => {*/}
            {/*        return (*/}
            {/*          <Box*/}
            {/*            sx={{ width: "100%", height: "440px", padding: "10px" }}*/}
            {/*          >*/}
            {/*            <Skeleton*/}
            {/*              variant="rounded"*/}
            {/*              height={220}*/}
            {/*              sx={{ width: "100%" }}*/}
            {/*            />*/}
            {/*            <Skeleton*/}
            {/*              variant="rounded"*/}
            {/*              width={113}*/}
            {/*              height={30}*/}
            {/*              sx={{ marginTop: "10px" }}*/}
            {/*            />*/}
            {/*            <Skeleton*/}
            {/*              variant="text"*/}
            {/*              height={35}*/}
            {/*              sx={{ width: "100%", margin: "3px 0" }}*/}
            {/*            />*/}
            {/*            <Skeleton*/}
            {/*                variant="rounded"*/}
            {/*                width={100}*/}
            {/*                height={20}*/}
            {/*                sx={{ marginTop: "10px" }}*/}
            {/*            />*/}
            {/*            <Skeleton*/}
            {/*              variant="text"*/}
            {/*              height={30}*/}
            {/*              sx={{ width: "80%", margin: "3px 0" }}*/}
            {/*            />*/}
            {/*            <Skeleton*/}
            {/*              variant="text"*/}
            {/*              height={27}*/}
            {/*              sx={{ width: "30%", margin: "1px 0" }}*/}
            {/*            />*/}
            {/*            <Skeleton*/}
            {/*              variant="text"*/}
            {/*              height={24}*/}
            {/*              sx={{ width: "80%", marginTop: "5px" }}*/}
            {/*            />*/}
            {/*          </Box>*/}
            {/*        );*/}
            {/*      })*/}
            {/*    : handpickProperty.map((item, index) => (*/}
            {/*        <div*/}
            {/*          style={{ padding: "10px", height: "440px" }}*/}
            {/*          key={index}*/}
            {/*          aria-hidden={homeLoading ? "false" : "false"}*/}
            {/*        >*/}
            {/*          <HomePropertyItem*/}
            {/*            item={item}*/}
            {/*            onClickItem={this.onClickPropertyItem}*/}
            {/*            tabIndex={homeLoading ? "-1" : "0"}*/}
            {/*            aria-hidden={homeLoading ? "false" : "false"}*/}
            {/*          />*/}
            {/*        </div>*/}
            {/*      ))}*/}
            {/*</Carousel>*/}
            <Carousel
                responsive={responsive}
                ssr={true}
                draggable={false}
                swipeable={true}
                focusOnSelect={false}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
                beforeChange={(nextSlide) => (this.currentSlide = nextSlide)}
            >
              {homeLoading
                  ? _.map(Array(emptyArrayViewItem), (_, index) => (
                      <Box
                          key={`loading-${index}`}
                          sx={{ width: "100%", height: "440px", padding: "10px" }}
                          aria-hidden="true"
                      >
                        <Skeleton
                            variant="rounded"
                            height={220}
                            sx={{ width: "100%" }}
                            tabIndex="-1"
                        />
                        <Skeleton
                            variant="rounded"
                            width={113}
                            height={30}
                            sx={{ marginTop: "10px" }}
                            tabIndex="-1"
                        />
                        <Skeleton
                            variant="text"
                            height={35}
                            sx={{ width: "100%", margin: "3px 0" }}
                            tabIndex="-1"
                        />
                        <Skeleton
                            variant="rounded"
                            width={100}
                            height={20}
                            sx={{ marginTop: "10px" }}
                            tabIndex="-1"
                        />
                        <Skeleton
                            variant="text"
                            height={30}
                            sx={{ width: "80%", margin: "3px 0" }}
                            tabIndex="-1"
                        />
                        <Skeleton
                            variant="text"
                            height={27}
                            sx={{ width: "30%", margin: "1px 0" }}
                            tabIndex="-1"
                        />
                        <Skeleton
                            variant="text"
                            height={24}
                            sx={{ width: "80%", marginTop: "5px" }}
                            tabIndex="-1"
                        />
                      </Box>
                  ))
                  : handpickProperty.map((item, index) => {
                    const isVisible = index === this.currentSlide;
                    return (
                        <div
                            key={`property-${index}`}
                            style={{ padding: "10px", height: "440px" }}
                            aria-hidden={!isVisible}
                            tabIndex={isVisible ? "0" : "-1"}
                        >
                          <HomePropertyItem
                              item={item}
                              onClickItem={this.onClickPropertyItem}
                              tabIndex={isVisible ? "0" : "-1"}
                              aria-hidden={!isVisible}
                          />
                        </div>
                    );
                  })}
            </Carousel>
          </Container>
        </Box>
        {/*) : (*/}
        {/*  false*/}
        {/*)}*/}


        {/*{!_.isEmpty(latestProjects) ? (*/}
        <Box
          sx={{
            paddingTop: "2rem",
            paddingBottom: "1rem",
            backgroundColor: Colors.textWhite
          }}
        >
          {/*===== StandBy purpose=====*/}
          {/*<Container>*/}
          {/*  <Grid item md={12}>*/}
          {/*    <TitleItemContainer>*/}
          {/*      <CustomFont*/}
          {/*        styles={{*/}
          {/*          fontWeight: "600"*/}
          {/*        }}*/}
          {/*        variant="h1"*/}
          {/*      >*/}
          {/*        Latest Projects*/}
          {/*      </CustomFont>*/}
          {/*      /!*<Hidden smDown>*!/*/}
          {/*      /!*<CustomFont hover variant="p" size="large">*!/*/}
          {/*      /!*{`View more >`}*!/*/}
          {/*      /!*</CustomFont>*!/*/}
          {/*      /!*</Hidden>*!/*/}
          {/*    </TitleItemContainer>*/}
          {/*  </Grid>*/}
          {/*  <Carousel*/}
          {/*    responsive={responsive}*/}
          {/*    //showDots={true}*/}
          {/*    ssr={true}*/}
          {/*    draggable={false}*/}
          {/*    swipeable={true}*/}
          {/*    onMove*/}
          {/*    focusOnSelect={false}*/}
          {/*    // containerClass="homePageNewAndEventCarouselContainer"*/}
          {/*    dotListClass="custom-dot-list-style"*/}
          {/*    itemClass="carousel-item-padding-40-px"*/}
          {/*  >*/}
          {/*    {latestProjects.map((item, index) => {*/}
          {/*      return (*/}
          {/*        <div*/}
          {/*          style={{ padding: "10px", height: "100%" }}*/}
          {/*          key={index}*/}
          {/*        >*/}
          {/*          <HomeProjectItem*/}
          {/*            item={item}*/}
          {/*            onClickItem={this.onClickProjectItem}*/}
          {/*          />*/}
          {/*        </div>*/}
          {/*      );*/}
          {/*    })}*/}
          {/*  </Carousel>*/}
          {/*===== End of StandBy purpose=====*/}

          <Container>
            <Grid item md={12}>
              <TitleItemContainer>
                <CustomFont
                  styles={{
                    fontWeight: "600"
                  }}
                  variant="h2"
                >
                  Latest Projects
                </CustomFont>
              </TitleItemContainer>
            </Grid>
            <Carousel
              responsive={responsive}
              // showDots={true}
              ssr={true}
              draggable={false}
              swipeable={true}
              onMove
              focusOnSelect={false}
              // containerClass="homePageNewAndEventCarouselContainer"
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
            >
              {homeLoading
                ? _.map(Array(emptyArrayViewItem), () => {
                    return (
                      <Box
                        sx={{ width: "100%", height: "370px", padding: "10px" }}
                      >
                        <Skeleton
                          variant="rounded"
                          height={220}
                          sx={{ width: "100%" }}
                        />
                        <Skeleton
                          variant="rounded"
                          width={113}
                          height={30}
                          sx={{ marginTop: "10px" }}
                        />
                        <Skeleton
                          variant="text"
                          height={30}
                          sx={{ width: "100%", margin: "5px 0" }}
                        />
                        <Skeleton
                          variant="text"
                          height={30}
                          sx={{ width: "80%" }}
                        />
                      </Box>
                    );
                  })
                : latestProjects.map((item, index) => (
                    <div
                      style={{ padding: "10px", height: "370px" }}
                      key={index}
                      aria-hidden={homeLoading ? "true" : "false"}
                    >
                      <HomeProjectItem
                        item={item}
                        onClickItem={this.onClickProjectItem}
                        tabIndex={homeLoading ? "-1" : "0"}
                        aria-hidden={homeLoading ? "true" : "false"}
                      />
                    </div>
                  ))}
            </Carousel>
          </Container>
        </Box>

        <Box
          sx={{
            paddingTop: "2rem",
            paddingBottom: "1rem",
            backgroundColor: Colors.greyBackgroundColor
          }}
        >
          <Container>
            <Grid item md={12}>
              <TitleItemContainer>
                <CustomFont
                  styles={{
                    fontWeight: "600"
                  }}
                  variant="h2"
                >
                  Latest Guides
                </CustomFont>
              </TitleItemContainer>
            </Grid>
            <Carousel
              responsive={responsive}
              // showDots={true}
              ssr={true}
              draggable={false}
              swipeable={true}
              onMove
              focusOnSelect={false}
              // containerClass="homePageNewAndEventCarouselContainer"
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
            >
              {homeLoading
                ? _.map(Array(emptyArrayViewItem), () => {
                    return (
                      <Box
                        sx={{ width: "100%", height: "350px", padding: "10px" }}
                      >
                        <Skeleton
                          variant="rounded"
                          height={200}
                          sx={{ width: "100%" }}
                        />
                        <Skeleton
                          variant="text"
                          height={35}
                          sx={{ marginTop: "10px", width: "100%" }}
                        />
                        <Skeleton
                          variant="text"
                          height={33}
                          sx={{ width: "100%", margin: "1px 0" }}
                        />
                        <Skeleton
                          variant="text"
                          height={29}
                          sx={{ width: "30%", marginTop: "5px" }}
                        />
                      </Box>
                    );
                  })
                : latestGuides.map((item, index) => (
                    <div
                      style={{ padding: "10px", height: "350px" }}
                      key={index}
                    >
                      <BlogCardItem
                        // isHideShadow
                        item={item}
                        onClickItem={this.onClickBlogItem}
                      />
                    </div>
                  ))}
            </Carousel>
          </Container>
        </Box>
        {/*======== End of Latest Guide ===========*/}

        {/*=========================LandingPage Section========================*/}
        <Box
          sx={{
            paddingTop: "2rem",
            paddingBottom: "2rem",
            backgroundColor: Colors.textWhite
          }}
        >
          <Container>
            <AgentPackageFeatured />
            <Box
                sx={{
                  display: { xs: 'none', md: 'block' }
                }}
            >
            <br />
            </Box>
            <Grid container spacing={2}>
              <Grid item md={1} xs={0} />

              <Grid item md={10} xs={12}>
                {/*=====New Currrent SignUp Landing====*/}
                <Box
                    sx={{
                      display: { xs: 'none', md: 'block' }
                    }}
                >
                  <SignUpBannerSection>
                    <SignUpLeftBannerContainer>
                      <Image
                        objectFit="contain"
                        src={"/images/signup-banner-main.png"}
                        layout="fill"
                        alt="signup-banner"
                      />
                    </SignUpLeftBannerContainer>
                    <SignUpBannerContainer>
                      <CustomFont
                        variant="h3"
                        styles={{
                          color: Colors.textWhite,
                          fontWeight: "400"
                        }}
                      >{`Streamline your property management with PropertyGenie and enjoy free expert advice!`}</CustomFont>
                      <CustomFont
                        variant="h2"
                        styles={{
                          color: Colors.textWhite,
                          fontWeight: "600"
                        }}
                      >{`Unlock Your Property’s  True Value with Genie Match`}</CustomFont>
                      <ButtonSignUpContainer>
                        <Button
                          // data-aos="zoom-in"
                          href={`${process.env.WEBSITE_URL}signup-landing`}
                          onClick={this.handleButtonLanding}
                          variant="contained"
                          style={{
                            color: Color.primaryColor,
                            fontWeight: "600",
                            borderRadius: "4px",
                            padding: "10px 40px",
                            fontSize: 15,
                            background: Color.textWhite,
                            marginTop: "1rem",
                            marginBottom: "1rem",
                            textTransform: "inherit"
                          }}
                        >
                          Sign-up Now
                        </Button>
                      </ButtonSignUpContainer>
                    </SignUpBannerContainer>
                  </SignUpBannerSection>
                </Box>
                <Box
                    sx={{
                      display: { xs: 'block', md: 'none' }
                    }}
                >
                  <SignUpBannerSection>
                    <SignUpLeftBannerContainer>
                      <Image
                        objectFit="contain"
                        src={"/images/signup-banner-main.png"}
                        layout="fill"
                        alt="signup-banner"
                      />
                    </SignUpLeftBannerContainer>
                    <SignUpBannerContainer>
                      <CustomFont
                        variant="h3"
                        styles={{
                          color: Colors.textWhite,
                          fontWeight: "400"
                        }}
                      >{`Streamline your property management with PropertyGenie and enjoy free expert advice!`}</CustomFont>
                      <CustomFont
                        variant="h2"
                        styles={{
                          color: Colors.textWhite,
                          fontWeight: "600"
                        }}
                      >{`Unlock Your Property’s  True Value with Genie Match`}</CustomFont>
                      <ButtonSignUpContainer>
                        <Button
                          // data-aos="zoom-in"
                          href={`${process.env.WEBSITE_URL}signup-landing`}
                          onClick={this.handleButtonLanding}
                          variant="contained"
                          style={{
                            color: Color.primaryColor,
                            fontWeight: "600",
                            borderRadius: "4px",
                            padding: "5px 20px",
                            fontSize: 14,
                            background: Color.textWhite,
                            marginTop: "1rem",
                            marginBottom: "1rem",
                            textTransform: "inherit"
                          }}
                        >
                          Sign-up Now
                        </Button>
                      </ButtonSignUpContainer>
                    </SignUpBannerContainer>
                  </SignUpBannerSection>
                </Box>
                <Grid item md={1} />
              </Grid>
            </Grid>
          </Container>
        </Box>
        {/*=========================End of LandingPage Section========================*/}
        {/*=========================Property Tool Section========================*/}
        <Box
          sx={{
            paddingTop: "2rem",
            paddingBottom: "2rem",
            backgroundColor: Colors.textWhite,

          }}
        >
          <Container>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center"
              }}
            >
              <PropertyToolTitleContainer>
                <CustomFont size="xlarge" styles={{ fontWeight: "600" }}>
                  {`Let's check out our property tool`}
                </CustomFont>
                <Divider
                  style={{
                    backgroundColor: Color.primaryColor,
                    opacity: 1,
                    height: "3px",
                    width: "47px",
                    marginTop: "1rem",
                    marginBottom: "1rem"
                  }}
                />
              </PropertyToolTitleContainer>
            </div>

            {homeLoading ? (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  marginTop: "2rem",
                  marginBottom: "2rem"
                }}
              >
                <Skeleton
                  variant="rounded"
                  height={159}
                  sx={{ width: "100%", margin: "0 8px" }}
                />
                <Skeleton
                  variant="rounded"
                  height={159}
                  sx={{ width: "100%", margin: "0 8px" }}
                />
                <Skeleton
                  variant="rounded"
                  height={159}
                  sx={{ width: "100%", margin: "0 8px" }}
                />
              </Box>
            ) : (
              <PropertyToolSection />
            )}
          </Container>
        </Box>
        {/*=========================End of Property Tool Section========================*/}
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const route = "homeBuy";
  const rentRoute = "homeRent";

  return {
    language: selectors.getGlobalLanguage(state),
    homeLoading: selectors.getHomeLoading(state),
    homeData: selectors.getHomeData(state),
    searchSuggestionLoading: selectors.getSearchSuggestionLoading(state),
    searchSuggestionItems: selectors.getSearchSuggestionItems(state, route),
    searchRentSuggestionItems: selectors.getSearchSuggestionItems(
      state,
      rentRoute
    ),
    commonDataState: selectors.getCommonDataState(state),
    commonDataCity: selectors.getCommonDataCity(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLanguage: language => dispatch(globalActions.updateLanguage(language)),
    getHomeData: () => dispatch(globalActions.getHomeDataRequest()),
    setFilterData: (route, data) =>
      dispatch(searchActions.setFilterData(route, data)),
    postSearchSuggestion: (route, postData) =>
      dispatch(searchActions.postSearchSuggestionRequest(route, postData)),
    clearSearchSuggestion: route =>
      dispatch(searchActions.clearSearchSuggestion(route)),
    setCoordinateData: data => dispatch(searchActions.setCoordinateData(data))
  };
}

export default withTheme(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Home)
);
