import Image from "next/image";
import React, { Component } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import { Color, Color as Colors } from "../../../src/utils/Color";
import { withTheme, useTheme } from "styled-components";
import styled from "styled-components";
import Hidden from "@material-ui/core/Hidden";
import CustomFont from "../../../components/CustomFont";
import { maxDevice } from "../../../src/utils/breakpoints";
import Router from "next/router";
import * as selectors from "../../../src/selectors";
import * as globalActions from "../../../src/actions/global";
import { connect } from "dva";
import * as globalSelectors from "../../../src/selectors/global";
import * as newsSelectors from "../../../src/selectors/news";
import _ from "lodash";
import * as searchActions from "../../../src/actions/search";
import Constants from "../../../src/constants";
import Enviroment from "../../../src/utils/Environment";
import Helpers from "../../../src/utils/Helpers";
import { NextSeo } from "next-seo";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

const PropertyToolMobileContainer = styled.div`
  display: block;
  @media ${maxDevice.lg} {
    display: flex;
    justify-content: center;
  }
`;
const PropertyToolContainer = styled.div`
  background: ${Color.greyBackgroundColor};
  border-radius: 4px;
  display: flex;
  padding: 20px;
  //flex-direction: column;
  align-items: center;
  @media ${maxDevice.lg} {
    width: 500px;
  }
`;
const PropertyToolTextContent = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 600;
  & a {
    color: ${Colors.primaryColor};
    display: inline-block;
    padding-bottom: 2px;
    background-image: linear-gradient(#3462f4 0 0);
    background-position: 0 100%;
    background-size: 0% 2px;
    background-repeat: no-repeat;
    transition: background-size 0.3s, background-position 0s 0.3s;
    width: fit-content;
  }
  & a:hover {
    //transition: 200ms ease-in;
    background-position: 100% 100%;
    background-size: 100% 2px;
  }
`;
const PropertyToolImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 120px;
  margin-right: -20px;
`;

class PropertyToolSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div style={{ marginTop: "2rem", marginBottom: "2rem" }}>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <PropertyToolMobileContainer>
              <PropertyToolContainer>
                <PropertyToolTextContent>
                  <CustomFont styles={{ fontWeight: "600" }}>
                    Estimate & Check Loan Eligibility
                  </CustomFont>
                  <a
                    href={`${process.env.WEBSITE_URL}home-loan-calculator`}
                    style={{
                      // textDecorationLine: "underline",
                      // color: Colors.primaryColor,
                      cursor: "pointer",
                      marginTop: "0.4rem",
                      display: "flex",
                      // alignItems: "center",
                      fontSize: "14px"
                    }}
                  >
                    {`Calculate & Apply Now`}{" "}
                    <KeyboardDoubleArrowRightIcon fontSize="small" />
                  </a>
                </PropertyToolTextContent>
                <PropertyToolImageContainer>
                  <Image
                    src="/images/Layer_1.png"
                    layout="fill"
                    objectFit="contain"
                    alt=""
                  />
                </PropertyToolImageContainer>
              </PropertyToolContainer>
            </PropertyToolMobileContainer>
          </Grid>
          <Grid item md={4} xs={12}>
            <PropertyToolMobileContainer>
              <PropertyToolContainer>
                <PropertyToolTextContent>
                  <CustomFont styles={{ fontWeight: "600" }}>
                    Insider Guide to real estate
                  </CustomFont>
                  <a
                    href={`${process.env.WEBSITE_URL}insider-guide`}
                    style={{
                      // textDecorationLine: "underline",
                      color: Colors.primaryColor,
                      cursor: "pointer",
                      marginTop: "0.4rem",
                      display: "flex",
                      // alignItems: "center",
                      fontSize: "14px"
                    }}
                  >
                    {`Read Now`}{" "}
                    <KeyboardDoubleArrowRightIcon fontSize="small" />
                  </a>
                </PropertyToolTextContent>
                <PropertyToolImageContainer>
                  <Image
                    src="/images/right-2.png"
                    layout="fill"
                    objectFit="contain"
                    alt=""
                  />
                </PropertyToolImageContainer>
              </PropertyToolContainer>
            </PropertyToolMobileContainer>
          </Grid>
          <Grid item md={4} xs={12}>
            <PropertyToolMobileContainer>
              <PropertyToolContainer>
                <PropertyToolTextContent>
                  <CustomFont styles={{ fontWeight: "600" }}>
                    The Excitement and Thrill of Auctions
                  </CustomFont>
                  <a
                    href={`${process.env.WEBSITE_URL}coming-soon`}
                    style={{
                      // textDecorationLine: "underline",
                      color: Colors.primaryColor,
                      cursor: "pointer",
                      marginTop: "0.4rem",
                      display: "flex",
                      // alignItems: "center",
                      fontSize: "14px"
                    }}
                  >
                    {`View More`}{" "}
                    <KeyboardDoubleArrowRightIcon fontSize="small" />
                  </a>
                </PropertyToolTextContent>
                <PropertyToolImageContainer>
                  <Image
                    src="/images/right-3.png"
                    layout="fill"
                    objectFit="contain"
                    alt=""
                  />
                </PropertyToolImageContainer>
              </PropertyToolContainer>
            </PropertyToolMobileContainer>
          </Grid>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default withTheme(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PropertyToolSection)
);
